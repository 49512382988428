/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Avenir LT Pro 45 Book';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 45 Book'), url('../fonts/AvenirFonts/AvenirLTProBook.woff') format('woff');
}
    
    
@font-face {
    font-family: 'Avenir LT Pro 45 Book Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 45 Book Oblique'), url('../fonts/AvenirFonts/AvenirLTProBookOblique.woff') format('woff');
}
    
    
@font-face {
    font-family: 'Avenir LT Pro 55 Roman';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 55 Roman'), url('../fonts/AvenirFonts/AvenirLTProRoman.woff') format('woff');
}
    
    
@font-face {
    font-family: 'Avenir LT Pro 55 Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 55 Oblique'), url('../fonts/AvenirFonts/AvenirLTProOblique.woff') format('woff');
}
    
    
@font-face {
    font-family: 'Avenir LT Pro 35 Light';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 35 Light'), url('../fonts/AvenirFonts/AvenirLTProLight.woff') format('woff');
}
    
    
@font-face {
    font-family: 'Avenir LT Pro 35 Light Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 35 Light Oblique'), url('../fonts/AvenirFonts/AvenirLTProLightOblique.woff') format('woff');
}
    
    
@font-face {
    font-family: 'Avenir LT Pro 65 Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 65 Medium'), url('../fonts/AvenirFonts/AvenirLTProMedium.woff') format('woff');
}
    
    
@font-face {
    font-family: 'Avenir LT Pro 65 Medium Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 65 Medium Oblique'), url('../fonts/AvenirFonts/AvenirLTProMediumOblique.woff') format('woff');
}
    
    
@font-face {
    font-family: 'Avenir LT Pro 85 Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 85 Heavy'), url('../fonts/AvenirFonts/AvenirLTProHeavy.woff') format('woff');
}
    
    
@font-face {
    font-family: 'Avenir LT Pro 85 Heavy Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 85 Heavy Oblique'), url('../fonts/AvenirFonts/AvenirLTProHeavyOblique.woff') format('woff');
}
    
    
@font-face {
    font-family: 'Avenir LT Pro 95 Black';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 95 Black'), url('../fonts/AvenirFonts/AvenirLTProBlack.woff') format('woff');
}
    
    
@font-face {
    font-family: 'Avenir LT Pro 95 Black Oblique';
    font-style: normal;
    font-weight: normal;
    src: local('Avenir LT Pro 95 Black Oblique'), url('../fonts/AvenirFonts/AvenirLTProBlackOblique.woff') format('woff');
}