@font-face {
    font-family: 'SFProDisplayMedium';
    src: local('SFProDisplayMedium'), url('../fonts/woff-formatted/SF-Pro-Display-Medium.woff') format('woff')
}

@font-face {
    font-family: 'SFProDisplayRegular';
    src: local('SFProDisplayRegular'), url('../fonts/woff-formatted/SF-Pro-Display-Regular.woff') format('woff')
}

@font-face {
    font-family: 'SFProDisplaySemibold';
    src: local('SFProDisplaySemibold'), url('../fonts/woff-formatted/SF-Pro-Display-Semibold.woff') format('woff')
}

@font-face {
    font-family: 'SFProTextBold';
    src: local('SFProTextBold'), url('../fonts/woff-formatted/SF-Pro-Text-Bold.woff') format('woff')
}

@font-face {
    font-family: 'SFProTextMedium';
    src: local('SFProTextMedium'), url('../fonts/woff-formatted/SF-Pro-Text-Medium.woff') format('woff')
}

@font-face {
    font-family: 'SFProTextRegular';
    src: local('SFProTextRegular'), url('../fonts/woff-formatted/SF-Pro-Text-Regular.woff') format('woff')
}